// Tooltip
$(function () {
  $('[data-toggle="tooltip"]').tooltip();
  //
  // $("#hamburger").on("click", function () {
  //   $("#hamburger").toggleClass("open");
  // });
});

// Make Images in Texarea Responsive
// if (!$("#textarea img").hasClass("img-fluid")) {
//     $("#textarea img").addClass("img-fluid");
// }

// Clean up Tables in Texarea
$("table").removeAttr("style").removeAttr("border");
$("td").removeAttr("style");
$("thead").addClass("bg-secondary text-white");

// Make iFrame Responsive
// $( "iframe" ).wrap( "<div class='embed-responsive embed-responsive-16by9'></div>" );

//Make Social Mail ikon title "mailto:"
$(".socialmedia .fa-envelope-square").each(function () {
  var oldMailTitle = $(this).attr("title");
  var newMailTitle = oldMailTitle.replace("http://", "mailto:");
  $(this).attr("title", newMailTitle);
});

//Make Social Mail ikon href "mailto:" link
$(".socialmedia .fa-envelope-square")
  .parent()
  .each(function () {
    var oldUrl = $(this).attr("href");
    var newUrl = oldUrl.replace("http://", "mailto:");
    $(this).attr("href", newUrl);
  });

// Add Tab hash to URL
$(function () {
  var hash = window.location.hash;
  hash && $('ul.nav a[href="' + hash + '"]').tab("show");
  $(".nav-item a").click(function (e) {
    $(this).tab("show");
    var scrollmem = $("body").scrollTop() || $("html").scrollTop();
    window.location.hash = this.hash;
    $("html,body").scrollTop(scrollmem);
  });
});

// Shrinking Navbar
// (new IntersectionObserver(function(e,o){
//     if (e[0].intersectionRatio > 0){
//         document.documentElement.removeAttribute('id');
//     } else {
//         document.documentElement.setAttribute('id','nav-at-top');
//     };
// })).observe(document.querySelector('#nav-anker'));

//  Scroll to top
// $("#scrolltop").on("click", function () {
//   $("html, body").animate({ scrollTop: 0 }, "slow", function () {
//     console.log("Top");
//   });
// });

// Switch Stylesheet
// var cssStyle = document.getElementById("switchStyle");
// window.onload = function () {
//   if (localStorage && localStorage.getItem("switchStyle"))
//     cssStyle.href = localStorage.getItem("switchStyle");
// };

// function setStyle(newStyle) {
//   cssStyle.href = newStyle;
//   if (localStorage) localStorage.setItem("switchStyle", newStyle);
// }
